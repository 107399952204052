import { useEffect, useState } from "react";

export function SweetMessage(props: { text: string; onRemove: () => void }) {
  const [top, setTop] = useState<number>(Math.floor(Math.random() * 90));
  const [left, setLeft] = useState<number>(Math.floor(Math.random() * 90));

  useEffect(() => {
    const timer = setTimeout(() => {
      props.onRemove();
      console.log("aaa");
    }, 5000); // Change 5000 to the desired duration in milliseconds

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [props.onRemove]);
  return (
    <div
      className="something-sweet"
      style={{
        top: top < 5 ? `${top + 5}%` : `${top}%`,
        left: left < 5 ? `${left + 5}%` : `${left}%`,
      }}
    >
      {props.text}
    </div>
  );
}

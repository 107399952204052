import { useState } from "react";

export function UsPicture(props: { photoList: string[] }) {
  function getRandomInt(min: number, max: number) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
  }

  const [currPhoto, setCurrPhoto] = useState<string>(
    props.photoList[getRandomInt(0, props.photoList.length)]
  );

  return (
    <div className="usPicture">
      <p style={{ margin: "0px" }}>A Photo I love</p>
      <img className="usPictures" src={currPhoto} alt="logo" />
    </div>
  );
}
